<template>
  <div class="infoVendas">
    <!-- FILTROS -->
    <div class="Secao01 q-ma-md">
      <div class="filter">
        <div class="box q-pa-xs bg-verde">
          <div class="title text-white text-h6 row bg-verde justify-around" style="border-bottom: 5px solid var(--primary)">
            <div class="row justify-end" style="width: 50%">Filtros</div>
            <div class="row justify-end" style="width: 50%">
              <q-btn flat icon="delete_sweep" @click="onReset" />
            </div>
          </div>
          <div class="body row bg-white">
            <div class="col-10">
              <div class="AnoMes">
                <q-select square outlined dense class="Ano q-my-sm q-ml-xs" v-model="category" :options="categoria" label="Categoria" />
                <q-input square outlined dense class="Mes q-my-sm q-ml-xs" v-model="dateAjusted" type="text" label="Período" stack-label>
                  <q-popup-proxy class="datePicker">
                    <q-date v-model="period" range :locale="ptBR">
                      <q-btn color="negative" label="Cancelar" flat v-close-popup />
                      <q-btn color="primary" label="Confirmar" flat v-close-popup @click="onDate" />
                    </q-date>
                  </q-popup-proxy>
                </q-input>
              </div>
            </div>
            <div class="col-1 col-xs-2">
              <div class="full-height row justify-center">
                <q-btn v-if="isMobile" flat class="text-primary q-my-sm" icon="search" @click="onSearch" />
                <q-btn v-else round class="text-white q-my-sm" icon="search" @click="onSearch" style="background-color: var(--primary)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- RANKING & FLUXO CAIXA -->
    <div class="q-ma-md">
      <!-- RANKING -->
      <div class="top3">
        <div class="box bg-verde">
          <div class="title bLTR text-h6 text-white">
            Ranking - TOP 3 Vendas
          </div>
          <q-table
            class="bA"
            :rows="topVendas"
            :columns="cols"
            color="verde"
            dense
            square
            hide-bottom
          />
        </div>
      </div>


      <!-- SELETOR DAS OPÇÕES DO FLUXO DE CAIXA -->
      <div class="q-mt-md q-mb-xs row">
        <q-btn
          no-caps
          :outline="!panelSells"
          label="Vendas"
          class="text-white"
          :color="!panelSells ? 'black' : null"
          style="width: 33.3%; background-color: var(--primary)"
          @click="filterSells"
        />
        <q-btn
          no-caps
          :outline="!panelOS"
          label="Ordens Serviços"
          class="text-white"
          :color="!panelOS ? 'black' : null"
          style="width: 33.3%; background-color: var(--primary)"
          @click="filterOS"
        />
        <q-btn
          no-caps
          :outline="!panelAll"
          label="Vendas + OS's"
          class="text-white"
          :color="!panelAll ? 'black' : null"
          style="width: 33.3%; background-color: var(--primary)"
          @click="filterAll"
        />
      </div>

      <!-- FLUXO DE CAIXA -->
      <div class="fluxo" style="height: 100%;">
        <div class="box bg-verde">
          <div class="title bLTR text-h6 text-white">
            Fluxo de Caixa
          </div>
          <div class="row bg-verde bA" style="height: 100%;">
            <div class="cardsFluxo">
              
              <q-card square class="receita_bruta">
                <div class="title row bB justify-center bg-verdeclaro">
                  <div class="icon">
                    <q-icon
                      v-if="!isMobile"
                      class="q-mr-sm"
                      name="local_atm"
                      size="25px"
                    />
                  </div>
                  <div class="text text-subtitle1 text-bold">
                    Receita Bruta
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ parseFloat(receitas).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                  </div>
                  <div class="btn">
                    <!-- <q-btn v-if="isMobile" flat no-caps dense class="text-caption" color="primary" icon="bar_chart" label="Gráfico" @click="onGraphic('Receita')" />
                    <q-btn v-else flat no-caps dense color="primary" icon="bar_chart" label="Gráfico" @click="onGraphic('Receita')" /> -->
                  </div>
                </div>
              </q-card>

            </div>
            <div class="cardsFluxo">

              <q-card square class="despesa_bruta">
                <div class="title row bB justify-center bg-verdeclaro">
                  <div class="icon">
                    <q-icon
                      v-if="!isMobile"
                      class="q-mr-sm"
                      name="receipt_long"
                      size="25px"
                    />
                  </div>
                  <div v-if="isMobile" class="text text-subtitle1 text-bold">
                    Desp. Bruta
                  </div>
                  <div v-else class="text text-subtitle1 text-bold">
                    Despesa Bruta
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ parseFloat(despesas).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                  </div>
                  <div class="btn">
                    <!-- <q-btn v-if="isMobile" flat no-caps dense class="text-caption" color="primary" icon="bar_chart" label="Gráfico" @click="onGraphic('Despesa')" />
                    <q-btn v-else flat no-caps dense color="primary" icon="bar_chart" label="Gráfico" @click="onGraphic('Despesa')" /> -->
                  </div>
                </div>
              </q-card>

            </div>
            <div class="cardsFluxo">

              <q-card square class="lucro_bruto">
                <div class="title row bB justify-center bg-verdeclaro">
                  <div class="icon">
                    <q-icon
                      v-if="!isMobile"
                      class="q-mr-sm"
                      name="savings"
                      size="25px"
                    />
                  </div>
                  <div class="text text-subtitle1 text-bold">
                    Lucro Bruto
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ parseFloat(lucroBruto).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                  </div>
                  <!-- <div class="btn">
                    <q-btn v-if="isMobile" flat no-caps dense class="text-caption" color="primary" icon="bar_chart" label="Gráfico" @click="onGraphic('Lucro')" />
                    <q-btn v-else flat no-caps dense color="primary" icon="bar_chart" label="Gráfico" @click="onGraphic('Lucro')" />
                  </div> -->
                </div>
              </q-card>

            </div>
            <div class="cardsFluxo">

              <q-card square class="despesa_liquida">
                <div class="title row bB justify-center bg-verdeclaro">
                  <div class="icon">
                    <q-icon
                      v-if="!isMobile"
                      class="q-mr-sm"
                      name="receipt"
                      size="25px"
                    />
                  </div>
                  <div v-if="isMobile" class="text text-subtitle1 text-bold">
                    Desp. Líquida
                  </div>
                  <div v-else class="text text-subtitle1 text-bold">
                    Despesa Líquida
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ parseFloat(custos).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                  </div>
                </div>
              </q-card>

            </div>
            <div class="cardsFluxo">

              <q-card square class="lucro_liquido">
                <div class="title row bB justify-center bg-verdeclaro">
                  <div class="icon">
                    <q-icon
                      v-if="!isMobile"
                      class="q-mr-sm"
                      name="insights"
                      size="25px"
                    />
                  </div>
                  <div class="text text-subtitle1 text-bold">
                    Lucro Líquido
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ isNaN(parseFloat(lucroLiquido)) ? 0 : parseFloat(lucroLiquido).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                  </div>
                  <!-- <div class="btn">
                    <q-btn v-if="isMobile" flat no-caps dense class="text-caption" color="primary" icon="bar_chart" label="Gráfico" @click="onGraphic('Lucro')" />
                    <q-btn v-else flat no-caps dense color="primary" icon="bar_chart" label="Gráfico" @click="onGraphic('Lucro')" />
                  </div> -->
                </div>
              </q-card>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- GRÁFICO DAS RECEITAS / DESPESAS -->
    <div class="Secao03 q-ma-lg">
      <div class="graphic">
        <apexchart
          type="area"
          height="350"
          :options="chartOption"
          :series="dataSeries"
        />
      </div>
    </div>

    <!-- QUANTIDADES VENDIDAS -->
    <div class="volumes q-ma-md">
      <div class="bodys">
        <div class="box q-pa-xs bg-verde">
          <div class="title text-white text-h6 row bg-verde justify-around" style="border-bottom: 5px solid var(--primary)">
            <div class="row justify-center">Quantidades Vendidas</div>
          </div>
          <div class="body row bg-white">
            <div class="responsive">
              <div class="row justify-center">
                <div
                  class="
                    digitos
                    text-verde
                    text-bold
                    q-my-sm
                    q-py-lg
                  "
                  v-for="placar in placarVendas"
                  :key="placar"
                >
                  {{ placar }}
                </div>
              </div>
            </div>
            <div class="responsive">
              <div class="row justify-around">
                <div class="col q-my-md" v-for="s in qtdTam" :key="s">
                  <div v-if="isMobile" class="title text-subtitle2 text-bold text-verde q-mx-sm" style="border-bottom: 3px solid var(--primary)">{{ s.tam }}</div>
                  <div v-else class="title text-h6 text-bold text-verde q-mx-sm" style="border-bottom: 3px solid var(--primary)">{{ s.tam }}</div>
                  <div
                    v-if="isMobile"
                    class="body text-h5 text-bold text-verde"
                    style="font-family: 'ZCOOL QingKe HuangYou'"
                  >{{ s.qtd }}</div>
                  <div
                    v-else
                    class="body text-h3 text-bold text-verde"
                    style="font-family: 'ZCOOL QingKe HuangYou'"
                  >{{ s.qtd }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- REDES SOCIAIS -->
    <div class="volumes q-ma-md">
      <div class="bodys">
        <div class="box q-pa-xs bg-verde">
          <div class="title text-white text-h6 row bg-verde justify-around" style="border-bottom: 5px solid var(--primary)">
            <div class="row justify-center">Redes Sociais</div>
          </div>
          <div class="body row bg-white">
            <div class="full-width row justify-center">
              <div class="row justify-center">
                <div class="col justify-center">
                  <div
                    class="
                      bodyNetSocial
                      text-verde
                      text-h6
                      text-bold
                      q-my-sm
                      row
                      bg-cinzaescuro
                    "
                    :id="net.net"
                    v-for="net in socialNets"
                    :key="net"
                  >
                    <div class="icon col-3 q-py-sm" >
                      <q-icon
                        color="white"
                        :name="net.icon"
                        size="40px"
                      />
                    </div>
                    <div class="qtdDestaque col-8 column justify-center q-my-sm">
                      <div class="destaque">
                        {{ net.qtd.toLocaleString('pt-BR') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Loading, QSpinnerHourglass } from 'quasar';
// import { globaisVar } from '../../../../globais/GlobalVar';
// import FormatFloat from '../../../helpers/Format_Float';
import { mapActions } from 'vuex';
import VueApexCharts from 'vue3-apexcharts';
// import MonthtoNumber from '../../../helpers/Month_Name_For_Number';
import { categorys } from '../../../globais/Categorys'

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      category: 'Geral',
      period: null,
      dateAjusted: null,
      dt_ini: null,
      dt_fim: null,
      // optCategory: globaisVar.optCategory,
      categoria: [],
      optYear: [
        '2020', '2021', '2022', '2023', '2024', '2025'
      ],
      optMonth: [
        'Todos', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ],
      topVendas: [],
      cols: [
        { name: 'product', align: 'left',    label: 'Produto',    field: 'team'},
        { name: 'qtd',  align: 'center',  label: 'Vendas',   field: 'qtd' },
        { name: 'vlr',  align: 'center',  label: 'Receita',  field: 'vlr', format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) },
      ],
      despesas: 0,
      receitas: 0,
      receitasSells: 0,
      receitasOSs: 0,
      receitasAll: 0,
      lucroBruto: 0,
      lucroLiquido: 0,
      custos: 0,
      pedidos: 0,
      chartOption: {
        chart: {
          stacked: false,
          zoom: { enabled: false },
        },
        legend: {
          onItemClick: {
              toggleDataSeries: false
          },
          onItemHover: {
              highlightDataSeries: false
          },
        },
        colors: ['#53906F', '#DD5C6B'],
        xaxis: {
          categories: [
            'Jan','Fev','Mar','Abr','Mai','Jun',
            'Jul','Ago','Set','Out','Nov','Dez'
          ]
        }
      },
      dataSeries: [],
      placarVendas: [],
      qtdTam: [],
      socialNets: [],
      panelSells: false,
      panelOS: false,
      panelAll: true,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    ...mapActions("Functions", [ "rpc" ]),
    onReset() {
      this.category = '';
      this.dt_ini = '20000101';
      this.dt_fim = '40001231';
      this.period = null;
      this.dateAjusted = null;

      this.topVendas = [];

      this.onSearch();
    },
    ajustedDate(date) {
      var strDate = date + '';
      var splitDate = strDate.split('/');

      var day = splitDate[2];
      var month = splitDate[1];
      var year = splitDate[0];

      var dt = `${day}/${month}/${year}`

      return dt;
    },
    onDate() {
      if(this.period.to) {
        this.dt_ini = String(this.period.from).replaceAll('/','');
        this.dt_fim = String(this.period.to).replaceAll('/','');

        this.dateAjusted = `
          ${this.ajustedDate(this.period.from)} 
          - 
          ${this.ajustedDate(this.period.to)}
        `
      } else {
        this.dt_ini = String(this.period).replaceAll('/','');
        this.dt_fim = String(this.period).replaceAll('/','');

        this.dateAjusted = `
          ${this.ajustedDate(this.period)} 
          - 
          ${this.ajustedDate(this.period)}
        `
      }
    },
    async onSearch() {
      // ACIONANDO DO LOADING
      Loading.show({
        title: 'Gestão Eletrônicos',
        message: 'Realizando os filtros, aguarde...',
        spinner: QSpinnerHourglass
      });

      // ZERANDO VALORES DOS GRÁFICOS
      this.dataSeries = [];

      // VALIDAÇÃO DOS CAMPOS
      let category = '';
      
        // LIGA
        if(this.category === 'Geral' || this.category === '' || this.category === null) {
          category = '%%';
        } else {
          category = this.category;
        }

        // // ANO
        // if(this.year === '' || this.year === null) {
        //   yearI = 2000;
        //   yearF = 4000;
        // } else {
        //   yearI = this.year;
        //   yearF = this.year;
        // }

        // // MES
        // if(this.month === 'Todos' || this.month === '' || this.month === null) {
        //   monthI = '0101';
        //   monthF = '1231';
        // } else {
        //   monthI = MonthtoNumber(this.month) + '01';
        //   monthF = MonthtoNumber(this.month) + '31';
        // }

        // PERIODO
        if(this.period === '' || this.period === null) {
          this.dt_ini = '20000101';
          this.dt_fim = '40001231';
        }

      // TOP3 VENDAS
      this.topVendas = await this.rpc({
        function: 'top3_vendas',
        enterprise: localStorage.getItem('enterprise'),
        league: category,
        date_start: this.dt_ini,
        date_end: this.dt_fim,
      })

      // console.log(this.topVendas);

/*
OK ~> DESPESA BRUTA = PEDIDOS + DESPESA            
OK ~> RECEITA BRUTA = RECEITA - DESPESA BRUTA (Não seria receita liquida?)


DESPESA LIQUIDA = CUSTO UNIT. * QUANTIDADE DE CAMISAS VENDIDAS
LUCRO LIQUIDO = RECEITA - DESPESA LIQUIDA
*/


      // DESPESA (NÃO FILTRA POR LIGA, APENAS POR DATA)
      let desp = await this.rpc({
        function: 'total_despesas',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })
      this.despesas = parseFloat(desp[0].vlr + desp[1].vlr);
      this.pedidos = desp[0].vlr === null ? 0 : parseFloat(desp[0].vlr)

      //desp[0].vlr === Aba Pedidos
      //desp[1].vlr === Aba Despesas

      // RECEITA VENDAS (NÃO FILTRA POR LIGA APENAS POR DATA)
      let rec = await this.rpc({
        function: 'total_vendas',
        enterprise: localStorage.getItem('enterprise'),
        league: '%%',
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })
      this.receitasSells = rec[0].vlr == null
        ? parseFloat(0)
        : parseFloat(rec[0].vlr);

      // RECEITA OSs (NÃO FILTRA POR LIGA APENAS POR DATA)
      let os = await this.rpc({
        function: 'total_os',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })

      let valOS = 0
      os.map(el => {
        el.items.map(it => {
          valOS += it.price
        })
      })

      this.receitasOSs = parseFloat(valOS);

      this.receitasAll = this.receitasSells + this.receitasOSs
      this.receitas = this.receitasAll


      // CUSTO (NÃO FILTRA POR LIGA APENAS POR DATA)
      let cost = await this.rpc({
        function: 'total_cost',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })
      this.custos = cost[0].vlr == null || isNaN(cost[0].vlr)
        ? parseFloat(0)
        : parseFloat(cost[0].vlr)

      // LUCRO
      this.lucroBruto = parseFloat(this.receitas) - parseFloat(this.despesas)

      this.lucroLiquido = parseFloat(this.receitas) - (this.custos * 1) - parseFloat(this.pedidos);

      // GRÁFICO -> SÉRIE DA RECEITA
      let graf_vendas = await this.rpc({
        function: 'graf_vendas',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      });

      let serieReceita = [];
      graf_vendas.forEach(gv => {
        serieReceita.push(gv.vlr)
      });

        //~> Montando o Object com as Receitas
        const receitas = {
          name: 'Receita',
          data: serieReceita
        };

        this.dataSeries.push(receitas);

      // GRÁFICO -> SÉRIE DA DESPESAS
      let graf_despesa = await this.rpc({
        function: 'graf_despesas',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      });

      let serieDespesa = [];
      for (let d = 0; d < graf_despesa.length; d++) {      
        for (let mesD = 0; mesD < 12; mesD++) {
          let numInt = mesD.toLocaleString('pt-br', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })

          if(graf_despesa[d].mes == numInt) {
            let valor = parseFloat(graf_despesa[d].vlr)
            serieDespesa[mesD-1] = valor;
          }
        }
      }

        //~> Montando o Object com as Despesas
        const despesas = {
          name: 'Despesa',
          data: serieDespesa
        };

        this.dataSeries.push(despesas);

      // RETORNANDO OS TOTALIZADORES DOS VOLUMES DE VENDAS
      let placar = await this.rpc({
        function: 'qtdade_vendida_total',
        enterprise: localStorage.getItem('enterprise'),
        league: '%%',
        date_start: this.dt_ini,
        date_end: this.dt_fim
      });

        //~> Agrupando as vendas para alimentar o Placar Geral
        let volTTL = 0;
        for(let p=0; p < placar.length; p++) {
          volTTL += parseFloat(placar[p].qtd);
        }

        let aVol = num => Number(num);
        this.placarVendas = Array.from(
          String(volTTL),
          aVol
        )

        //~> Carregando informações das Vendas por Armazenamentos
        this.qtdTam = placar

      // PREENCHENDO OS DADOS DAS REDES SOCIAIS
      let nets = await this.rpc({
        function: 'historico_redes_sociais',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      });

        this.socialNets = nets

      // CARREGANDO AS CATEGORIAS
      let categoria = await categorys();

      this.categoria = categoria.map((cat) => {
        return cat.GE_DESCRIPTION
      })

      // FINALIZANDO O LOADING
      Loading.hide();
    },
    filterOS() {
      this.panelOS = true
      this.panelSells = false
      this.panelAll = false

      this.receitas = this.receitasOSs
      this.lucroBruto = this.receitas - parseFloat(this.despesas)
      this.lucroLiquido = parseFloat(this.receitas) - parseFloat(this.custos) - parseFloat(this.pedidos)
    },
    filterSells() {
      this.panelOS = false
      this.panelSells = true
      this.panelAll = false

      this.receitas = this.receitasSells
      this.lucroBruto = this.receitas - parseFloat(this.despesas)
      this.lucroLiquido = this.receitas - parseFloat(this.custos) - parseFloat(this.pedidos)
    },
    filterAll() {
      this.panelOS = false
      this.panelSells = false
      this.panelAll = true

      this.receitas = this.receitasAll
      this.lucroBruto = this.receitas - parseFloat(this.despesas)
      this.lucroLiquido = this.receitas - parseFloat(this.custos) - parseFloat(this.pedidos)
    }
  },
  created() {
    this.onSearch();
  },
}
</script>

<style lang="scss">
  @import url('../../../globais/GlobalStyles.scss');

  .datePicker {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
</style>