<template>
    <div class="mvv row justify-center">
        <div class="col-md-8 col-xs-11">
          <q-carousel
            v-model="slide"
            transition-prev="scale"
            transition-next="scale"
            swipeable
            animated
            control-text-color="black"
            prev-icon="undo"
            next-icon="redo"
            navigation-icon="sports_soccer"
            navigation
            padding
            arrows
            class="carousel text-verde q-ma-md"
          >
            <q-carousel-slide v-for="m in mvv" :key="m" :name="m.field" class="column no-wrap flex-center">
              <div class="q-mt-md text-center text-bold text-h5">
                {{ m.title }}
              </div>
              <div class="q-mt-xs text-caption text-center">
                {{ m.message }}
              </div>
            </q-carousel-slide>
          </q-carousel>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Loading, QSpinnerHourglass } from 'quasar';

export default {
  data() {
    return {
      slide: 'missao',
      mvv: {},
    }
  },
  methods: {
    ...mapActions("Querys", ["getDataWithoutFilters"]),
  },
  async created() {
    Loading.show({
      title: 'Gestão Eletrônicos',
      message: 'Carregando seus dados, aguarde...',
      spinner: QSpinnerHourglass
    });

    let response = await this.getDataWithoutFilters({
      table: 'GE_SETTINGS',
      fields: 'GE_MISSAO, GE_VISAO, GE_VALORES',
      enterprise: localStorage.getItem('enterprise')
    })

    this.mvv.missao = { title: 'Missão', field: 'missao', message: response[0].GE_MISSAO };
    this.mvv.visao = { title: 'Visão', field: 'visao', message: response[0].GE_VISAO };
    this.mvv.valores = { title: 'Valores', field: 'valores', message: response[0].GE_VALORES };

    Loading.hide();
  }
}
</script>

<style lang="scss">
  .mvv {
    background-image: url('../../../assets/eletro.jpg');
    background-size: 100%;
    background-position: center;
    background-position-x: 0px;

    .carousel {
      border-radius: 10px;
      box-shadow: 0px 0px 25px blue;
    }
  }

      
  /* Celulares (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px) {
    .mvv {
      background-position-y: -320px;
    }
    .carousel {
      height: 280px;
    }
  }

  /* Tablets (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .mvv {
      background-position-y: -360px;
    }
    .carousel {
      height: 250px;
    }
  }

  /* Desktop (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .mvv {
      background-position-y: -400px;
    }
    .carousel {
      height: 200px;
    }
  }
</style>