<template>
  <div class="home">    
    <MVV />
    <InfoVendas />
  </div>
</template>

<script>
import MVV from './Sections/MVV.vue';
import InfoVendas from './Sections/InfoVendas.vue';
import { defaultColor } from '../../helpers/defaultColor';

export default {
  components: {
    MVV,
    InfoVendas
  },
  created() {
    defaultColor()
  }
}
</script>
