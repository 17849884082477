const { supabase } = require('../supabase')

const categorys = async () => {
  const enterprise = localStorage.getItem('enterprise');

  const { data } = await supabase
    .from('GE_CATEGORY')
    .select('GE_DESCRIPTION, GE_TYPE')
    .not('DELETED', 'eq', '*')
    .or('GE_ENTERPRISE.eq.ALL,GE_ENTERPRISE.eq.' + enterprise)

  return data
}

export { categorys }